import {
  staticPath_assets_lp_top_arrow_down_png,
  staticPath_assets_lp_top_arrow_right_png,
  staticPath_assets_lp_top_star_svg,
  staticPath_assets_lp_top_storm_png,
} from "lib/$asset-path"
import { ChevronRightIcon } from "@chakra-ui/icons"
import {
  BoxProps,
  Button,
  Center,
  CenterProps,
  chakra,
  Stack,
  StackProps,
  VStack,
} from "@chakra-ui/react"
import { NextImage, NextImageInlineWrapper } from "components/system"

import NextLink from "next/link"
import { PropsWithChildren } from "react"
import { ConsultingCaseData } from "./data"
export type ConsultingCaseProps = {
  data: ConsultingCaseData
  withButtonLink?: boolean
  withHeader?: boolean
} & BoxProps

export const ConsultingCase = ({
  data,
  withButtonLink,
  withHeader,
  ...props
}: ConsultingCaseProps) => {
  const { kind, kindI18n, customerProfile, before, after } = data

  return (
    <VStack
      spacing={4}
      layerStyle="base"
      w="full"
      maxW="container.content"
      py={{ base: 6, md: 12 }}
      {...props}
    >
      {withHeader && <ConsultingCaseHeader title={kindI18n} />}
      <ConsultingCaseCustomerProfile customerProfile={customerProfile} />
      <ConsultingCaseBody before={before} after={after} />
      {withButtonLink && (
        <NextLink href={`/consulting/${kind}`} passHref legacyBehavior>
          <Button
            as="a"
            size="lg"
            colorScheme="secondary"
            rightIcon={<ChevronRightIcon boxSize="24px" />}
            fontSize={kind === "nisa" || kind === "insurance" ? "14px" : "16px"}
            w={{ base: "300px", md: "380px" }}
            pl={2}
            pr={0}
          >
            マネイロが{kindI18n}でできること
          </Button>
        </NextLink>
      )}
    </VStack>
  )
}

const ConsultingCaseHeader = ({ title }) => {
  return (
    <chakra.h3
      bgColor="moneiro-color-darkblue"
      w="full"
      color="white"
      textAlign={"center"}
      textStyle={{ base: "16-bold", md: "24-bold" }}
      py={{ base: 1, md: 2 }}
      borderTopLeftRadius="md"
      borderTopEndRadius="md"
      mt={{ base: "-30px", md: "-60px" }}
      mb={{ md: "15px" }}
    >
      {title}のご相談事例
    </chakra.h3>
  )
}

export const ConsultingCaseCustomerProfile = ({
  customerProfile,
  ...props
}: {
  customerProfile: ConsultingCaseData["customerProfile"]
} & CenterProps) => {
  const { meta, yearlyIncome, img } = customerProfile

  return (
    <Center
      w="full"
      pb={{ base: 4, md: 2 }}
      borderBottomWidth="2px"
      borderBottomColor="moneiro-color-main-orange"
      {...props}
    >
      <Stack
        spacing={4}
        direction={{ base: "column", md: "row" }}
        align={{ base: "initial", md: "flex-end" }}
        textAlign="center"
        pr={{ base: `${img.base.width}px`, md: `${img.md.width}px` }}
        pos="relative"
      >
        <chakra.div textStyle={{ base: "12-reg", md: "16-reg" }}>
          {meta}
        </chakra.div>
        <chakra.div
          textStyle={{ base: "12-bold", md: "16-bold" }}
          color="moneiro-color-main-orange"
        >
          年収
          <chakra.span
            fontSize={{ base: "36px", md: "52px" }}
            letterSpacing="0.065em"
            px={1}
          >
            {yearlyIncome}
          </chakra.span>
          万円
        </chakra.div>
        <NextImageInlineWrapper
          display={{ base: "block", md: "none" }}
          pos="absolute"
          right="-21px"
          bottom="-21px"
        >
          <NextImage alt="" unoptimized {...img.base} />
        </NextImageInlineWrapper>
        <NextImageInlineWrapper
          display={{ base: "none", md: "block" }}
          pos="absolute"
          right="-21px"
          bottom="-11px"
        >
          <NextImage alt="" unoptimized {...img.md} />
        </NextImageInlineWrapper>
      </Stack>
    </Center>
  )
}

export const ConsultingCaseBody = ({
  before,
  after,
  ...props
}: {
  before: ConsultingCaseData["before"]
  after: ConsultingCaseData["after"]
} & StackProps) => {
  return (
    <Stack
      spacing={{ base: 4, md: 8 }}
      direction={{ base: "column", md: "row" }}
      {...props}
    >
      <Before before={before} />
      <Arrow />
      <After after={after} />
    </Stack>
  )
}

const Before = ({ before }: { before: ConsultingCaseData["before"] }) => {
  const { amount, method, graph, comments } = before

  return (
    <VStack spacing={2}>
      {amount && method && (
        <chakra.div textStyle={{ base: "12-bold", md: "14-bold" }}>
          {method === "funded" && (
            <chakra.span color="moneiro-color-main-orange">毎月</chakra.span>
          )}
          <chakra.span
            fontSize={{ base: "36px", md: "38px" }}
            letterSpacing="0.065em"
            color="moneiro-color-main-orange"
            px="3px"
          >
            {amount}
          </chakra.span>
          <chakra.span color="moneiro-color-main-orange">万円</chakra.span>
          <chakra.span>の</chakra.span>
          <chakra.span textStyle={{ base: "16-bold", md: "18-bold" }} pl={1}>
            {method === "funded" ? "積立・" : null}運用例
          </chakra.span>
        </chakra.div>
      )}
      <NextImageInlineWrapper display={{ base: "block", md: "none" }}>
        <NextImage alt="" {...graph.base} />
      </NextImageInlineWrapper>
      <NextImageInlineWrapper display={{ base: "none", md: "block" }}>
        <NextImage alt="" {...graph.md} />
      </NextImageInlineWrapper>
      <chakra.ul listStyleType="none" w={{ base: "270px", md: "290px" }}>
        {comments.map((comment, idx) => {
          return <List key={idx}>{comment}</List>
        })}
      </chakra.ul>
    </VStack>
  )
}

const Arrow = () => {
  return (
    <>
      <NextImageInlineWrapper
        display={{ base: "block", md: "none" }}
        alignSelf="center"
      >
        <NextImage
          src={staticPath_assets_lp_top_arrow_down_png}
          width={50}
          height={15}
          alt=""
        />
      </NextImageInlineWrapper>
      <NextImageInlineWrapper
        display={{ base: "none", md: "block" }}
        alignSelf="center"
      >
        <NextImage
          src={staticPath_assets_lp_top_arrow_right_png}
          width={20}
          height={75}
          alt=""
        />
      </NextImageInlineWrapper>
    </>
  )
}

const After = ({ after }: { after: ConsultingCaseData["after"] }) => {
  const { graph, comments } = after

  return (
    <VStack spacing={2}>
      <NextImageInlineWrapper display={{ base: "block", md: "none" }}>
        <NextImage alt="" {...graph.base} />
      </NextImageInlineWrapper>
      <NextImageInlineWrapper display={{ base: "none", md: "block" }}>
        <NextImage alt="" {...graph.md} />
      </NextImageInlineWrapper>
      <chakra.ul listStyleType="none" w={{ base: "261px", md: "278px" }}>
        {comments.map((comment, idx) => {
          return (
            <List after key={idx}>
              {comment}
            </List>
          )
        })}
      </chakra.ul>
    </VStack>
  )
}

const List = ({
  children,
  after,
}: PropsWithChildren<{
  after?: boolean
}>) => {
  const bgImage = after
    ? `url(${staticPath_assets_lp_top_star_svg})`
    : `url(${staticPath_assets_lp_top_storm_png})`

  return (
    <chakra.li
      pl={5}
      pb={{ base: 1, md: 2 }}
      mb={{ base: 1, md: 2 }}
      // 画像サイズを指定したいのでlistStyleImageではなく背景画像を使用
      backgroundImage={bgImage}
      backgroundSize={{ base: "15px", md: "16px" }}
      backgroundRepeat="no-repeat"
      backgroundPosition="left 0 top 0"
      textStyle={{ base: "12-bold", md: "14-bold" }}
      color={after ? "moneiro-color-main-orange" : "moneiro-color-text-black"}
      borderBottom="1px dashed"
      borderBottomColor="moneiro-color-line-gray-strong"
    >
      {children}
    </chakra.li>
  )
}
